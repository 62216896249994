<template>
  <div class="h-screen flex items-center justify-center overlay">
    <div class="gradient" />
    <UCard class="max-w-sm w-full bg-white/75 dark:bg-white/5 backdrop-blur">
      <UAuthForm
        :fields="fields"
        :validate="validate"
        :providers="providers"
        title="Welcome back"
        align="top"
        icon="i-heroicons-lock-closed"
        :ui="{ base: 'text-center', footer: 'text-center' }"
        :submit-button="{ trailingIcon: 'i-heroicons-arrow-right-20-solid' }"
        :loading="loading"
        @submit="onSubmit"
      >
        <template #description>
          Don't have an account? <NuxtLink
            to="mailto:sales@simpliflow.tax"
            class="text-primary font-medium"
          >Contact Sales</NuxtLink>.
        </template>

        <template #password-hint>
          <NuxtLink
            to="/"
            class="text-primary font-medium"
            tabindex="1"
          >Forgot password?</NuxtLink>
        </template>

        <template
          v-if="loginError"
          #validation
        >
          <UAlert
            color="red"
            icon="i-heroicons-information-circle-20-solid"
            :title="loginError"
          />
        </template>

        <template #footer>
          By signing in, you agree to our <NuxtLink
            to="/"
            class="text-primary font-medium"
          >Terms of Service</NuxtLink>.
        </template>
      </UAuthForm>
    </UCard>
  </div>
</template>

<style scoped>
.gradient {
  position: absolute;
  inset: 0;
  pointer-events: none;
  background: radial-gradient(50% 50% at 50% 50%, rgb(var(--color-primary-500) / 0.25) 0, #FFF 100%);
}

.dark {
  .gradient {
    background: radial-gradient(50% 50% at 50% 50%, rgb(var(--color-primary-400) / 0.1) 0, rgb(var(--color-gray-950)) 100%);
  }
}

.overlay {
  background-size: 100px 100px;
  background-image:
    linear-gradient(to right, rgb(var(--color-gray-200)) 0.5px, transparent 0.5px),
    linear-gradient(to bottom, rgb(var(--color-gray-200)) 0.5px, transparent 0.5px);
}
.dark {
  .overlay {
    background-image:
      linear-gradient(to right, rgb(var(--color-gray-900)) 0.5px, transparent 0.5px),
      linear-gradient(to bottom, rgb(var(--color-gray-900)) 0.5px, transparent 0.5px);
  }
}
</style>

<script setup lang="ts">
import { FetchError } from 'ofetch'

const loading = ref(false)
const loginError = ref('')

const fields = [{
  name: 'email',
  type: 'email',
  label: 'Email',
  placeholder: 'Enter your email',
  tabindex: '0'
}, {
  name: 'password',
  label: 'Password',
  type: 'password',
  placeholder: 'Enter your password',
  tabindex: '0'
}]

const validate = (state: any) => {
  const errors = []
  if (!state.email) errors.push({ path: 'email', message: 'Email is required' })
  if (!state.password) errors.push({ path: 'password', message: 'Password is required' })
  return errors
}

const { setToken } = useAuthSession()
async function onSubmit(data: any) {
  loading.value = true
  loginError.value = ''
  try {
    // Perform the login, which will send a JWT token in response.
    const token = await $fetch('/api/auth/login', { method: 'POST', body: data })
    loading.value = false
    setToken(token)
  } catch (e) {
    if (e instanceof FetchError) {
      loginError.value = e.statusMessage
      loading.value = false
    } else {
      loginError.value = 'Unknown error'
      loading.value = false
      // TODO: need some sort of error tracker like sentry
      console.log('error', e)
    }
  }
}

const providers = []
</script>

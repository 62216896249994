import { useStorage } from '@vueuse/core'

// useStorage will store, automatically, in local-storage
const useTokenState = () => useStorage('auth-token', () => null)
const decodedToken = computed(() => jwtDecode(useTokenState().value))

// Simple function to decode the useful part of the jwt.
const jwtDecode = (token) => {
  if (!token) return null
  return JSON.parse(atob(token.split('.')[1]))
}

export function useAuthSession() {
  const token = useTokenState()
  return {
    loggedIn: computed(() => !!token.value),
    userId: computed(() => decodedToken.value?.id || ''),
    name: computed(() => decodedToken.value?.name || ''),
    email: computed(() => decodedToken.value?.email || ''),
    token,
    setToken,
    clearToken
  }
}

async function setToken(token) {
  useTokenState().value = token
}

async function clearToken() {
  useTokenState().value = null
}
